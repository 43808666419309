.toggle {
    $this: &;

    margin-left: auto;

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
    }

    label {
        @keyframes slide {
            0% {
                left: 2px;
                transform: translate(0, -50%);
            }

            100% {
                left: calc(100% - 2px);
                transform: translate(-100%, -50%);
            }
        }

        @keyframes slideOff {
            0% {
                left: calc(100% - 2px);
                transform: translate(-100%, -50%);
            }

            100% {
                left: 2px;
                transform: translate(0, -50%);
            }
        }

        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 30px;
        border-radius: 30px;
        position: relative;
        margin: 5px 0;

        &.inactive {
            background: rgb(103, 106, 108);
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 2px;
            transform: translate(0, -50%);
            width: 25px;
            height: 25px;
            background: #fff;
            border-radius: 25px;
            animation: slideOff 0.3s;
        }
    }

    input:checked+label {
        &:after {
            animation: slide 0.3s;
            left: calc(100% - 2px);
            transform: translate(-100%, -50%);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        #{$this} {
            margin-left: 0;
        }
    }
}
