.elementListBox {
    &--minified {
        .table {
            .Actions {
                span {
                    flex-direction: row;
                    justify-content: left;
                    .btn {
                        margin: 2px;
                        width: 25px;
                        height: 25px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
    .table .Actions, .table .DefaultGroup {
        width: 1%;
    }
}

.elementListBoxEditButtons {
    display: flex;
    a {
        margin-bottom: 0 !important;
    }
}

.elementListBoxEllipsis {
    font-size: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;

    &.open {
        transform: rotateZ(90deg);
    }
}

.elementListBoxDropdownWrapper {
    position: relative;
}

.elementListBoxDropdown {
    position: absolute;
    right: -15px;
    top: 20px;
    padding: 0;
    margin: 0;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border: 1px solid #ccc;
    z-index: 99;
    background-color: #fff;
    visibility: hidden;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        text-decoration: none;
        padding: 0.25em 0.25em;
        width: 80px;
        cursor: pointer;
    }

    &.open {
        visibility: visible;
    }
}
