.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;

    .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        i {
            content: '';
            display: block;
            margin: 0 auto;
            width: 15%;
            height: 15%;
            border-radius: 100%;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;

            &.initial {
                background-color: #000;
            }
        }
    }

    .sk-circle2 {
        transform: rotate(30deg);

        i {
            animation-delay: -1.1s;
        }
    }

    .sk-circle3 {
        transform: rotate(60deg);

        i {
            animation-delay: -1s;
        }
    }

    .sk-circle4 {
        transform: rotate(90deg);

        i {
            animation-delay: -0.9s;
        }
    }

    .sk-circle5 {
        transform: rotate(120deg);

        i {
            animation-delay: -0.8s;
        }
    }

    .sk-circle6 {
        transform: rotate(150deg);

        i {
            animation-delay: -0.7s;
        }
    }

    .sk-circle7 {
        transform: rotate(180deg);

        i {
            animation-delay: -0.6s;
        }
    }

    .sk-circle8 {
        transform: rotate(210deg);

        i {
            animation-delay: -0.5s;
        }
    }

    .sk-circle9 {
        transform: rotate(240deg);

        i {
            animation-delay: -0.4s;
        }
    }

    .sk-circle10 {
        transform: rotate(270deg);

        i {
            animation-delay: -0.3s;
        }
    }

    .sk-circle11 {
        transform: rotate(300deg);

        i {
            animation-delay: -0.2s;
        }
    }

    .sk-circle12 {
        transform: rotate(330deg);

        i {
            animation-delay: -0.1s;
        }
    }
}

@keyframes sk-circleBounceDelay {

    0%,
    100%,
    80% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

.loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .sk-circle {
        margin: 0;
    }
}

tbody .sk-circle {
    margin-top: 0;
    margin-bottom: 0;
}

.reportCard__summary .sk-circle{
    margin: 20px auto;
}