input,
textarea {
    @include input;

    &[disabled] {
        background-color: #ddd;
    }

    &[type=checkbox]:disabled+label {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &.error {
        background-color: lighten(red, 40%);
        color: darken(red, 10%);
        border-color: darken(red, 10%);
    }

    &[type="file"] {
        border: 0;
    }

    &[type=number]:focus{
        box-shadow:none;
    }

    // for not Firefox
    @supports not( -moz-appearance:none ){
        &[type=number]:invalid {
            box-shadow:none;
            outline-offset: -2px;
            outline-style: auto;
            outline-width: 5px;
            outline-color: red;
        }
    }
}

/* Safari 10.1+ hack to get red borders on invalid number inputs */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    input {
        &[type=number]:invalid {
            outline-style: solid;
            outline-width: 2px;
        }
    }
}}

textarea {
    resize: vertical;
    min-height: 40px;
}

select {
    @include input;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;

    option {
        padding: 10px;
    }
}

.date__wrapper {
    position: relative;
    width: 100%;
    max-width: 150px;
    min-width: 150px;
}

.date_multiselect_wrapper {
    position: relative;
    width: 100%;
    max-width: 150px;
    min-width: 150px;
}

.date_multiselect_wrapper:focus-within {
    border-radius: 5px;
    border: 1px solid rgb(1, 4, 21) !important;
}

.Select-clear-zone,
.Select-arrow-zone {
    display: none !important;
}
.Select-menu-outer {
    display: inline-block !important;
    z-index: 1001;
}

.select-auto-suggest .Select-option:nth-child(n + 6) {
    display: none;
}
.select-auto-suggest .Select-option:last-child,
.select-auto-suggest .Select-option:nth-child(5) {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

// .Select {
//     width: 8000px;
//     max-width: 100%;
// }
.Select-control {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-right: 40px;
    height: 38px;
}

.Select-control:after {
    content: "\F002";
    width: 25px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "FontAwesome";
    display: flex;
    align-items: center;
    margin-left: auto;
}

.select {
    width: 175px;
    min-width: 175px;
}

.select-wrapper {
    margin-left: "0px",
}

.select-wrapper:focus-within {
    border-radius: 5px;
    border: 2px solid rgb(1, 4, 21) !important;
}

.icon-up::after {
    content: "\02C4";
}

.icon-down::after {
    content: "\02C5";
}

.select__control {
    width: 100%;
    min-width: 175px;
    margin-left: auto;
    transition: width 0.2s ease-in-out;
    &--is-focused {
        width: 100%;
    }
    border-radius: 5px !important;
}

.input-group .form-control {
    z-index: 0;
}

.select__wrapper {
    position: relative;
    width: 100%;
    max-width: 150px;
    min-width: 150px;

    select {
        padding-right: 20px;
        height: 38px;
    }

    &:after {
        content: "\F0D7";
        font-family: "FontAwesome";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: none;
        }
    }
}

.multiselect-container.dropdown-menu {
    .checkbox {
        white-space: normal !important;
        word-break: break-all;
    }
    .checkbox input[type="checkbox"] {
        margin-left: -30px;
        width: 30px;
        margin-top: 6px;
    }
}

.form__group .select__wrapper {
    max-width: 100%;
    min-width: 100%;
}

.form_group .form_wrapper {
    max-width: 100%;
    min-width: 100%;
}

.input-group {
    .form-control {
        border-color: #ccc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .input-group-addon {
        border-color: #ccc;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

select[multiple] {
    padding: 0;

    option {
        padding: 10px 0 10px 10px;
        font-size: 14px;
        line-height: 16px;
    }
}

.Select {
    .Select-control {
        width: 200px;
        transition: width 0.2s ease-in-out;

        .Select-multi-value-wrapper {
            .Select-value {
                white-space: nowrap;
            }
        }
    }

    &.is-focused {
        .Select-control {
            height: auto;
            width: 100%;
            min-width: 200px;
        }
    }
}

.table__filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    & > input,
    & > select {
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }

    & > input {
        flex: 1;
    }

    & > select {
        flex: 0.2;
    }

    .search {
        position: relative;

        input[type="text"] {
            width: 50px;
            transition: width 0.2s ease-in-out;
            padding-right: 25px;
            position: relative;

            &:focus {
                width: 200px;
            }
        }

        .fa {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    &__option {
        display: inline-block;
        margin-left: 10px;

        h5 {
            margin-bottom: 5px;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .filters__right {
        margin-left: auto;
        display: flex;
        flex-direction: row;

        .multiselect-native-select,
        .select__wrapper {
            margin-left: 0;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .term__toggle {
        $this: &;

        &__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__terms {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        &__term {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            margin-bottom: 10px;
            margin-left: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;

            input[type="checkbox"] {
                width: 22px;
                height: 22px;
                margin-right: 10px;
                margin-top: 0;
            }

            i {
                margin: 0 0 0 10px;
            }
        }

        &__add {
            flex-basis: 33%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid #ccc;

            input[type="text"] {
                margin-bottom: 10px;
            }

            .btn {
                margin-left: 0;
                margin-bottom: 0;
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}

.form {
    $this: &;
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        #{$this}__group {
            display: flex;
            flex-basis: 50%;
            flex-direction: column;
            padding-bottom: 20px;
            padding-right: 10px;
            border-right: 1px solid #ccc;
            margin-left: 10px;
            br {
                margin-top: 15px;
            }

            @media only screen and (max-width: 768px) {
                flex-basis: 100%;
                border: 0;
                padding-right: 0;
                margin-left: 0;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                border-right: 0;
                padding-right: 0;
            }

            &--full {
                flex-basis: 100%;
                border: 0;
                margin-left: 0;
                width: 100%;
            }

            #{$this}__label {
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 5px;

                a {
                    word-break: break-all;
                }

                &--alt {
                    border-top: 1px solid #ccc;
                    padding-top: 20px;
                    padding-left: 10px;
                    margin-bottom: 20px;
                    font-weight: 400;
                }
            }
            h3#{$this}__label {
                font-size: 18px;
                line-height: 18px;
            }

            #{$this}__value {
                font-size: 14px;
                line-height: 16px;

                &--centered {
                    padding: 0;
                    list-style: none;

                    li {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        &:last-child {
                            border-top: 1px solid #ccc;
                            padding-top: 10px;
                            margin-bottom: 0;
                        }

                        input {
                            flex-basis: 75%;
                        }

                        button {
                            flex-basis: 20%;
                        }
                    }
                }
            }

            select#{$this}__value {
                width: 100%;
                height: 100%;
            }

            &--full {
                flex-basis: 100%;
                margin-left: 0 !important;
                padding-right: 0 !important;
                border-right: 0 !important;
            }
        }

        table.form__value {
            width: 100%;

            tr {
                td,
                th {
                    padding-right: 10px;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        #{$this}__checkboxes {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            #{$this}__checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 10px;
                padding: 10px;
                justify-content: center;

                input[type="checkbox"] {
                    width: 12px !important;
                    height: 12px !important;
                    margin: 0 5px 0 0;
                }

                input[type="radio"] {
                    width: 13px !important;
                    height: 13px !important;
                    margin: 0 5px 0 0;
                }

                span {
                    font-size: 16px;
                    line-height: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    input[type="submit"] {
        @include btn;
        max-width: 250px;
        margin: 20px auto 0;
    }

    &__error {
        background-color: lighten(red, 25%);
        padding: 10px;
        border-radius: 5px;
        border: 1px solid darken(lighten(red, 25%), 40%);
        color: darken(lighten(red, 25%), 40%);
        margin-bottom: 20px;
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 9999;

        @media only screen and (max-width: 768px) {
            position: relative;
            top: 0;
            left: 0;
        }

        &--success {
            margin-top: 20px;
            margin-bottom: 0;
            background-color: lighten(lime, 25%);
            border: 1px solid darken(lighten(lime, 25%), 40%);
            color: darken(lighten(lime, 25%), 40%);
        }
    }
}

.tooltip__wrapper {
    display: inline-block;
    cursor: help;
    text-align: left;
    white-space: pre-wrap;
}

.tooltip__maxwidth .__react_component_tooltip {
    max-width: 400px;
}

.form-control {
    height: 38px !important;
    border-color: #ccc;
    border-radius: 5px;
}

.editable__input {
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 5px;
    position: relative;
    background: #f4f4f4;
    min-height: 36px;
    word-break: break-word;

    a {
        font-size: 14px;
        line-height: 16px;
        text-decoration: underline;
        word-wrap: break-word;
        overflow-wrap: anywhere;
    }

    &[contenteditable="true"]:hover {
        background: #efefef;

        &:after {
            content: "\F044";
            font-family: "FontAwesome";
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    &--edit {
        padding: 0;
        background: transparent;

        input,
        textarea {
            padding: 10px;
            font-size: 14px !important;
            line-height: 16px !important;
            width: 100%;
            height: 100%;
            padding-right: 30px;
        }

        &:hover {
            background: transparent;
        }

        &:after {
            visibility: hidden;
        }

        .fa {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            padding: 5px;
            background: #efefef;
            border: 1px solid #ccc;
            border-radius: 2px;
        }
    }
}

.ibox-content {
    hr {
        margin-bottom: 0;
    }
}

.clientSocialNetwork {
    $this: &;
    flex: 1;
    padding: 10px 10px 0 10px;
    margin: 10px 10px 10px;
    border: 1px solid #ccc;

    h4 {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
        padding-bottom: 5px;
    }

    .permission {
        width: 100%;
        border: none !important;
        margin-top: 5px;
        padding: 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        padding-bottom: 5px;

        @media (min-width: 1200px) {
            flex-direction: row;

            #{$this} {
                margin-top: 0;
            }
        }
    }
}

.offerCampaign {
    .form__label {
        display: flex;
    }
}

.domain__filters {
    &--sep {
        position: relative;

        hr {
            margin: 40px 0;
            border-color: #333;
            border-width: 2px;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            padding: 0 10px;
            color: #333;
            font-size: 18px;
        }
    }
    .form__row {
        border-top: $border;

        .form__group {
            padding: 20px 10px;
            margin: 0;

            &.domain__filters--tags {
                flex-basis: 100%;

                .form__label {
                    text-align: left;
                }

                .Select {
                    width: 100%;

                    .Select-control {
                        width: auto;
                        padding-right: 0;
                        text-align: left;

                        &::after {
                            display: none;
                        }
                    }

                    .Select-value {
                        background-color: lighten($brandPrimary, 50%);
                        color: $brandPrimary;
                        border-color: $brandPrimary;

                        .Select-value-icon {
                            border-color: $brandPrimary;
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;

                            &:hover,
                            &:active {
                                background-color: $brandPrimary;
                                color: lighten($brandPrimary, 50%);
                            }
                        }
                    }
                }
            }
        }

        &:first-of-type {
            border-top: 0;
        }

        &.domain__filters__add {
            background: lighten($brandPrimary, 40%);
        }
        &.form__headers {
            border: 0;
            .form__group {
                padding: 0;
                text-align: center;
            }
        }
    }
}
select:disabled {
    background-color: #ddd;
}
.Select.is-disabled {
    .Select-placeholder {
        background-color: #ddd;
    }
    > .Select-control {
        background-color: #ddd;
    }
}

div.copy-link, div.edit-sso-id {
    position: relative;
    width: 100%;
    input {
        padding-right: 45px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #ddd;
    }
    button {
        position: absolute;
        right: 0px;
        top: 0px;
        line-height: 100%;
    }
}

.editable-textarea {
    resize: vertical;
    overflow-y: scroll;
    max-height: 100%;
    height: 100%;
    min-height: 90px;
}

.lockhashtagToggle{
    border-top: 0px !important;
}

.white-space-normal {
    white-space: normal!important;
}

.red-outline {
    border: #B30000 solid 2px;
}

.red-outline-children {
    & > div:not(.select__menu):not(.rdtPicker) {
        border: #B30000 solid 2px !important;
    }
    & > .form-control {
        border: #B30000 solid 2px !important;
    }
}

.form-li-style{
    border-top: 0px !important;
}

.closeButton {
    position: relative;
    z-index: 1;
    padding: 8px 0 0 6px;
    top: -5px;
    right: -80px;
    margin-left: -15px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    font-size: 16px;
    line-height: 0;
    border-radius: 50%;
    background-color: #000;
    color: #fff
}

.options-container {
    right: 0px;
    text-align: left;
    box-sizing: border-box;
    z-index: 1;
    background-color: #fff;
    width: 100%;
    padding-top: 8px;
    border-radius: 4px;
    border: 1px solid var(--Neutrals-Silver, #CFD4DA);
    background:#FFF;
    transform: translateY(-3px);
}
.option-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.option-item:hover {
    background-color: #e8e8e8;
}
.profile-container {
    display: flex;
    padding: 0px 78px 0px 16px;
    align-items: center;
    gap: 10px;
}
.profile-picture {
    border-radius: 50%;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
}
.upperText {
    font-family: Lato,arial,sans-serif!important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    color: #343A40;
}
.lowerText {
    font-family: Lato,arial,sans-serif!important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    color:#535B64;
}
.li-profile-info {
    display: flex;
    flex-direction: column;
}
.linkedin-container {
    display: flex;
    align-items: center;
    background-color: #FAFAFA;
    height: 50px;
    font-family: Lato,arial,sans-serif!important;
    padding-left: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.linkedin-container img {
    margin-right: 10px;
    padding-left: 15px;
}

.selected-mentions {
    background-color: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #ccc;
    padding: 10px;
    padding-bottom: 6px;
    margin-bottom: 5px;
    display: flex;
    column-gap: 5px;
    font-size: 14px;
    text-align: left;
    flex-wrap: wrap;
}

.selected-mentions-text {
    color: #152733 !important;
}

.link{
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 20px!important;
    font-style: normal!important;
    cursor: pointer!important;
    text-decoration: underline!important;
}

.link:hover{
    text-decoration: underline!important;
}

.text-bold{
    font-weight: bold!important;
}