.percent-circle {
    position: relative;
    margin: 0 auto;

    span {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 18px;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        path {
            fill: none;
            stroke-width: 2.8;
            stroke-linecap: round;
            animation: progress 1s ease-out forwards;
        }
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}
