.react-autosuggest {
    $this: &;

    &__container {
        position: relative;
        width: 100%;
    }

    &__input {
        &--focused {
            outline: none;
        }

        &::-ms-clear {
            display: none;
        }

        &--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        #{$this}__suggestions-container {
            display: none;
        }
    }

    &__suggestions-container {
        &--open {
            @include input;
            position: absolute;
            top: 38px;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background-color: #fff;
            font-weight: 300;
            z-index: 1001;
            padding: 0;
            margin-bottom: 10px;
        }

        #{$this}__suggestions-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
    }

    &__suggestion {
        cursor: pointer;
        padding: 5px 20px;
        word-wrap: break-word;

        &--highlighted {
            background-color: #ddd;
        }
    }

    &__section {
        &-container {
            border-top: 1px dashed #ccc;

            &--first {
                border-top: 0;
            }
        }

        &-title {
            padding: 10px 0 0 10px;
            font-size: 12px;
            color: #777;
        }
    }
}
