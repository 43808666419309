.login {
    $this: &;

    &__wrapper {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 10px solid;
    }

    &__logo {
        width: 40px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        padding-bottom: 20px;
        label {
            display: block;
        }
        #{$this}__btns,
        #{$this}__email,
        #{$this}__username,
        #{$this}__password,
        #{$this}__criteria {
            width: 50%;
            margin-top: 10px;

            span {
                font-size: 14px;
                line-height: 16px;
                display: block;
            }

            input, a, button {
                font-weight: 400;
                @include input();
            }

            #{$this}__btn {
                border-radius: $borderRadius;
                color: #fff;
                transition: all 0.1s ease-in-out;
                padding: 10px 0;
                margin-top: 10px;
            }
        }
        #{$this}__email,
        #{$this}__username,
        #{$this}__password,
        #{$this}__criteria {
            width: 80%;
        }

        #{$this}__btns {
            margin-top: 10px;
        }
    }

    &__error {
        color: #ff0000;
        margin: 10px;
        font-size: 14px;
        line-height: 18px;
        width: 80%;
        text-align: center;
        padding: 10px;
    }
}

.page.login {
    width: 100vw;
    height: 100vh;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.criteria {

    margin-top: 10px;
    margin-left: 9px;

    p {
        margin: 0 0 0px;
        font-size: 13.5px;
        font-weight: 600;
    }
    .criteriaMet {
        color: #008A1F;
    }
    .criteriaNotMet {
        color:  #E71D32;
    }
}
