@mixin fontSize($size) {
    @if $size=='sm' {
        font-size: 12px;
        line-height: 14px;
    }

    @else if $size=='md' {
        font-size: 14px;
        line-height: 16px;
    }

    @else {
        font-size: 18px;
        line-height: 20px;
    }
}

.rdt {
    width: 100% !important;
    .btn {
        width: 100% !important;
        &__width_auto {
            width: auto !important;
        }
        &__width_90 {
            width: 90% !important;
        }
    }
}
.article-card {
    $this: &;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    background-color: white;

    &__container {
        display: flex;
        padding: 5px;
        flex: 0 0 30%; // IE needs some wiggle room, which is why we aren't using 33%
        min-width: 300px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            background: #fff;
            padding: 10px;
            cursor: pointer;


            &.header {
                cursor: default;
                background-color: #fafafa;
                border-bottom: 1px solid #e5e5e5;

                &:hover {
                    background-color: #fafafa;
                }
            }

            &.divider {
                height: 1px;
                margin: 0;
                padding: 0;
                overflow: hidden;
                background-color: #e5e5e5;
                cursor: default;
            }

            .tooltip__wrapper {
                display: inline-block;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__header {
        position: relative;

        #{$this}__close {
            float: left;

            .btn {
                border-radius: 50%;
                margin: 0;
                padding: 2px;
                font-size: 14px;
                line-height: 14px;

                i {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .dropdown {
            position: absolute;
            right: -10px;
            top: 20px;
            padding: 0;
            margin: 0;
            text-align: right;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            border: 1px solid #ccc;
            z-index: 99;
            visibility: hidden;

            &.open {
                visibility: visible;
            }
        }

        #{$this}__tools {
            position: relative;
            display: flex;

            b{
                word-break: break-all;
            }

            .fa-ellipsis-v {
                margin-left: auto;
                font-size: 18px;
                width: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 0.2s ease-in-out;

                &.open {
                    transform: rotateZ(90deg);
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
        padding: 10px;
        margin-top: 10px;

        #{$this}__title {
            font-weight: bold;
            margin-bottom: 0;
            @include fontSize('md');
            // word-break: break-word deprecated, use normal word-break and overflow-wrap instead
            word-break: normal;
            overflow-wrap: anywhere;
        }

        #{$this}__info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 5px;
            @include fontSize('sm');

            >* {
                margin-left: 5px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        #{$this}__body {
            padding-left: 10px;
            margin-top: 5px;
            @include fontSize('sm');
        }

        #{$this}__image__wrapper {
            width: 100%;
            max-height: 15em;
            position: relative;
            overflow: hidden;
        }

        #{$this}__image {
            max-width: 100%;
            max-height: 15em;
            display: block;
            margin: 5px auto 0;
        }
    }

    #{$this}__comment {
        margin-top: 10px;
        @include fontSize('md');
        white-space: pre-wrap;
        word-break: break-word;
    }

    &__footer {
        padding-top: 10px;
        margin-top: auto;

        #{$this}__tags {
            #{$this}__tag {
                @include fontSize('sm');
            }
        }
    }

    &__sharing-permissions {
        margin-top: 5px;
        @include fontSize('sm');
        &__text {
            text-transform: capitalize;
        }
    }

    #{$this}__lastModified {
        margin-top: 5px;
        @include fontSize('sm');
    }

    #{$this}__createdOn {
        margin-top: 5px;
        @include fontSize('sm');
    }

    .compliance__ribbon {
        background: red;
        position: relative;
        left: -15px;
        width: calc(100% + 30px);
        margin-top: 10px;
        margin-bottom: 0;
        padding: 10px;
        color: #fff;
        @include fontSize('md');

        .tooltip__wrapper {
            display: inline-block;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            display: block;
            border-style: solid;
            border-color: darken(red, 25%) transparent transparent transparent;
            bottom: -5px;
        }

        &:before {
            left: 0;
            border-width: 5px 0 0 5px;
        }

        &:after {
            right: 0;
            border-width: 5px 5px 0 0;
        }

        &.declined,
        &.rejected {
            background: red;

            &:after,
            &:before {
                border-top-color: darken(red, 25%);
            }
        }

        &.approved {
            background: green;

            &:after,
            &:before {
                border-top-color: darken(green, 25%);
            }
        }

        &.new,
        &.pending,
        &.suggested {
            background: orange;

            &:after,
            &:before {
                border-top-color: darken(orange, 25%);
            }
        }
    }

    #{$this}__approved {
        margin-top: 5px;
        @include fontSize('sm');
    }

    .footer__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 15px;

        .btn {
            flex: 1;
            margin-left: 5px;
            margin-bottom: 0;
            @include fontSize('md');

            &:first-child {
                margin-left: 0;
            }
        }
    }

    #{$this}__btn-group {
        margin-left: auto;
        margin-top: auto;

        #{$this}__btn {
            @include btn();
            width: 100%;
            font-size: 13px;
            padding: 5px 10px;
        }

        &--alt {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0 0;

            #{$this}__btn {
                flex: 1;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }

                .sk-circle {
                    margin: 0 auto;
                    width: 18px;
                    height: 18px;

                    .sk-child:before {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .engagement-stats {
        margin: 0;
        padding: 0;
        width: 100%;
        gap: .6rem;
        display: flex;
        align-items: center;

        &__item {
            color: #fff;
            align-items: center;
            padding: .3rem .6rem;
            border-radius: .8rem;
            background: rgba(153, 153, 153, 0.95);

            span { padding-right: .3rem;}
        }
    }
}

.footer__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;

    .btn {
        flex: 1;
        margin-left: 5px;
        margin-bottom: 0;
        @include fontSize('md');

        &:first-child {
            margin-left: 0;
        }
    }
}

.articleBadge {
    cursor: default;
    .fa {
        position: absolute;
        top: -20px;
        left: -20px;
        width: 25px;
        height: 25px;
        font-size: 14px;
        border-radius: 50%;
        background: #000;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 3px dotted;
    }

    &.rss {
        .fa {
            background-color: #f26522;
        }
    }

    &.facebook {
        .fa {
            background-color: #3b5998;
        }
    }

    &.instagram {
        .fa {
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
        }
    }

    &.twitter {
        .fa {
            background-color: #1da1f2;
        }
    }
    &.linkedin {
        .fa {
            background-color: #007bb6;
        }
    }
}

.articleList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .article-card {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}

.icon-play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    height: 60px !important;
    width: 60px !important;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    border-radius: 50%;
    background-color: rgba(17, 17, 17, 0.7);
    box-shadow: 0px 0px 50px rgb(128,128,128);
    > .ion-play{
        font-size:40px;
        color: rgb(211,211,211);
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
}

.bgcGrey {
    background-color: #eee!important;
}

video {
    width: 100%;
    max-height: 15em;
}