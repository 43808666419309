table {
    &.role__users {
        width: 100%;
        border: $border;
        max-height: 250px;
        overflow-y: scroll;

        tbody tr {
            cursor: pointer;

            &:nth-of-type(odd) {
                background-color: #eee;
            }
        }
        tr {
            border: $border;
            border-top: 0;
            td, th {
                padding: 5px;
                border-left: $border;

                &:first-child {
                    border-left: 0;
                }
            }

            &.clearfix {
                border-bottom: 0;

                td, th {
                    border-left: 0;
                }
            }

            &.selected {
                background-color: lighten($brandPrimary, 55%) !important;
                border: 1px solid $brandPrimary !important;
                td {
                    border-left-color: $brandPrimary !important;
                }
            }
        }
        thead tr {
            border: $border;
        }

        thead, tbody {
            display: flex;
            flex-direction: column;

            tr {
                display: flex;
                flex-direction: row;
                align-items: center;
                min-height: 45px;
                td, th{
                    flex-basis: 25%;
                    word-break: break-all;
                    line-height: 13px;
                    overflow-y: hidden;
                    display: flex;
                    align-items: center;
                    &:first-of-type {
                        flex-basis: 75%;
                    }
                    &.full {
                        flex-basis: 100%;
                    }
                    br {
                        margin-top: 5px !important;
                    }
                }
            }
        }
        tbody {
            height: 250px;
            overflow-y: scroll;
        }

        td {
            .tooltip__wrapper {
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-basis: 100%;
                > div:first-of-type {
                    width: 100%;
                }
            }
            span {
                margin-right: 10px;
            }
            .role__label {
                margin-right: 0;
                margin-left: auto;
                font-size: 10px;
                padding: 5px;
                border-radius: 10px;
                color: #fff;
                word-break: keep-all;
                border-width: 1px;
                border-style: solid;
            }
        }
    }

    th.Actions {
        min-width: 150px;
    }

    td {
        &.Actions span {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    tbody > tr > td,
    tbody > tr > th,
    tfoot > tr > td,
    tfoot > tr > th,
    thead > tr > td,
    thead > tr > th {
        overflow-wrap: break-word;
        word-break: break-all;
    }

}

// group list only
.groups-list table{
    @media only screen and (min-width:1400px) {
        th.Last.Updated {
            width: 300px;
        }
    }
}
