.reportCard {
    height: calc(100% - 48px - 25px);

    &__summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        flex-direction: column;

        &__section {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            flex-basis: 33%;

            h3 {
                font-size: 36px;
                line-height: 38px;

                small {
                    display: block;
                    color: #333;
                    font-size: 18px;
                    line-height: 24px;
                }
            }

        }
    }
}

.report-loading-backdrop {
    position: absolute;
    left: -10px;
    top: -20px;
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    z-index: 1020;
    background-color: rgba(255, 255, 255, 0.5);
    overflow-y: hidden;
    &__inner {
        margin-top: 96px;
        height: calc(100% - 96px);
        background-color: rgba(249, 249, 249, 1);
        box-sizing: border-box;
    }
    &__spinner {
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 350px;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }
}
