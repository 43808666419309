.ibox {
    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    &-tools {
        margin-left: auto;
        .btn {
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
