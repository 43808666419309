.fc-event-title {
    font-size: 10px;
    font-weight: bold;
    vertical-align: middle !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    // necessary because some genius behind the module decided to make these all grey,
    // meaning the textColor field they provide doesn't actually do anything by default
    color: inherit;
}

.fc-event {
    padding-top: 1px;
    padding-bottom: 1px;
}

.fc-daygrid-day {
    height: 80px;
    max-height: 80px;
    min-height: 80px;
}

.fc-daygrid-day-events {
    margin-bottom: 0 !important;
}

.fc-daygrid-day-top {
    line-height: 1;
}

.fc-icon-chevron-left {
    vertical-align: revert !important;
}

.fc-icon-chevron-right {
    vertical-align: revert !important;
}

.fc-button {
    height: 30px;
    max-height: 30px;
    min-height: 30px;
}

.fc-toolbar-title {
    margin-right: 145px !important;
}

.fc-prev-button {
    margin-left: 5px !important;
}

.fc-popover-body {
    height: 115px;
    overflow-y: scroll;
}

.notificationSidebarTable {
    margin-top: 10px;
    > tr {
        height: 30px;
        > td:first-child {
            font-weight: bold;
        }
        > td {
            vertical-align: top;
            min-width: 130px;
            word-break: break-all;
        }
    }
}

.notificationSidebarEditButtons {
    display: flex;
    justify-content: flex-end;
}

.notificationSidebarEllipsis {
    font-size: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;

    &.open {
        transform: rotateZ(90deg);
    }
}

.notificationSidebarDropdownWrapper {
    position: relative;
}

.notificationSidebarDropdown {
    position: absolute;
    right: 0px;
    top: 40px;
    padding: 0;
    margin: 0;
    text-align: right;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border: 1px solid #ccc;
    z-index: 99;
    background-color: #fff;
    visibility: hidden;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        text-decoration: none;
        padding: 0.5em 1.5em;
        cursor: pointer;
    }

    &.open {
        visibility: visible;
    }
}
.ellipsisWrapper {
    display: flex;
}