.alert {
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 1;
    transition: 0.5s opacity ease-in-out;

    .fa {
        color: #fff;
        margin-left: auto;
    }

    &.success {
        background-color: lighten(lime, 25%);
        border: 1px solid darken(lighten(lime, 25%), 40%);
        color: #007500;

        .fa {
            color: darken(lighten(lime, 25%), 40%);
        }
    }

    &.warning {
        background-color: lighten(orange, 25%);
        border: 1px solid darken(lighten(orange, 25%), 40%);
        color: darken(lighten(orange, 25%), 40%);

        .fa {
            color: darken(lighten(orange, 25%), 40%);
        }
    }

    &.error {
        background-color: lighten(red, 25%);
        border: 1px solid darken(lighten(red, 25%), 40%);
        color: darken(lighten(red, 25%), 40%);

        .fa {
            color: darken(lighten(red, 25%), 40%);
        }
    }

    &.hidden {
        display: flex !important;
        opacity: 0;
    }
    @at-root &s {
        position: fixed;
        top: 10px;
        right: 10px;
        width: 25vw;
        z-index: 9999;
        @media only screen and (max-width:768px) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}
