.flex {
    $this: &;
    display: flex;
    width: 100%;

    &__row {
        display: flex;
        flex-direction: row;
        width: 100%;

        &--center {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            >* {
                flex-grow: 1;
                flex-basis: 0;
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        width: 100%;

        &--center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}
