   .swagger-ui {
       pre {
           padding: 0;
           margin: 0;
           font-size: inherit;
           line-height: inherit;
           background-color: inherit;
           border: 0;
       }
       .btn {
           color: inherit !important;
           &:hover {
               background-color: transparent;
           }
       }
       .information-container {
           .info {
               margin-top: 0;
           }
       }
   }