.fade {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.fade.in {
    opacity: 1;
}

.loadingFadeInOut {
    animation: fadeInOutAnimation ease 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes fadeInOutAnimation {
    0% {
        opacity: .5;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: .5;
    }
}

.heading {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.subheading {
    font-size: 12px;
    font-weight: 400;
}

.radioSelOption {
    font-size: 14px;
    font-weight: 400;
    float: left;
    clear: none;
    margin: 2px 0 0 2px;
}

.radioSelOption:checked {
    accent-color: #07098D
}

.radioLabel {
    float: left;
    clear: none;
    display: block;
    padding: 0px 1em 0px 8px;
    font-weight: 400;
    font-size: 14px;
}

.darkBlue {
   color:  #07098D!important;
   font-weight: bold!important;
}

.filterDarkBlue {
    filter: invert(11%) sepia(81%) saturate(7080%) hue-rotate(241deg) brightness(55%) contrast(106%);
}

.box {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 1px solid #676a6c!important;
  outline-offset: 0px !important;
//   border: 3px solid white!important;
  width: 16px;
  height: 16px;
}

.box:checked {
    background: #07098D;
    outline-offset: 0px!important;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter:alpha(opacity=20);
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter:alpha(opacity=50);
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open {
    overflow: hidden;
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

.modal-bee-editor .modal-dialog {
    width: 95%;
}

.modal-ecommunication-message .modal-dialog {
    width: 85%;
}

.modal-bee-editor-custom-row-name-prompt .modal-dialog {
    width: 45%;
}

.modal-content {
    position: relative;
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.5);
    box-shadow: 0 3px 9px rgba(0,0,0,0.5);
    background-clip: padding-box;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
    filter:alpha(opacity=0);
}

.modal-backdrop.in {
    opacity: 0.5;
    filter:alpha(opacity=50);
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857143px;
}

.modal-header .close {
    margin-top: 0;
    transform: translate(0, 25%);
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
}

.modal-body {
    position: relative;
    padding: 15px;
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0;
}

.ViewSDK_parentRelativeHeight {
    height: 570px !important;
}
@media (min-width:768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }

    .overall .modal-dialog {
        width: 900px;
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,0.5);
        box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    }

    .modal-sm {
        width: 300px;
    }
}
@media (min-width:992px) {
    .modal-lg {
        width: 900px;
    }
}

.animated {
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(10px);
    }
}
@-moz-keyframes shake {
    0%,
    100% {
        -moz-transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -moz-transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -moz-transform: translateX(10px);
    }
}
@-o-keyframes shake {
    0%,
    100% {
        -o-transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -o-transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        -o-transform: translateX(10px);
    }
}
@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}

.shake {
    -webkit-animation-name: shake;
    -moz-animation-name: shake;
    -o-animation-name: shake;
    animation-name: shake;
}

.modal {
    display: block;
}

.modal-content > .close {
    position: absolute;
    top: 10px;
    right: 10px;
}
@media (min-width:768px) {
    .modal-sm > .modal-dialog {
        width: 300px;
    }
}
@media (min-width:992px) {
    .modal-lg > .modal-dialog {
        width: 900px;
    }
}

.modal-modal-background{
    background:#00000050;
}

.modal-header-none .modal-header{
    display: none;
}

.modal.fade:not(.in).left .modal-dialog {
	-webkit-transform: translate3d(-125%, 0, 0);
	transform: translate3d(-125%, 0, 0);
}
.modal.fade:not(.in).right .modal-dialog {
	-webkit-transform: translate3d(125%, 0, 0);
	transform: translate3d(125%, 0, 0);
}
.articlePreview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .modal-dialog {
        width: 90vw;
        height: 90vh;
        margin: auto;
        overflow: hidden;

        .modal-content {
            height: 100%;

            .modal-header {
                height: 70px;
            }
            .modal-body {
                height: calc(100% - 140px);
                overflow-y: scroll;
            }
        }
    }
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0px;
    &::before, &::after{
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }
    &::before{
        margin-right: .25em;
    }
    &::after{
        margin-left: .25em;
    }
}

.push-notification {
    max-width: 400px;
    max-height: 225px;
    background-color: rgba(192,192,192,0.15);
    border-radius: 10px;
    color: #000000;
    margin: 15px auto;
    padding: 10px;

    .notification-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.modal-notification {
    font-family: Lato,arial,sans-serif!important;
    max-width: 420px;
    background-color: rgba(192,192,192,0.15);
    // width: 525px;
    // background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(6px);
    text-align: center;
    color: #000000;
    margin: auto;
    padding: 10px 0;
  }

  .weekly-email-notification {
    font-family: Lato,arial,sans-serif!important;
    max-width: 420px;
    text-align: center;
    color: #000000;
    margin: auto;
    padding: 10px 0;
  }

  .card-preview {
    width: 90%;
    text-align: center;
    margin: auto;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1), 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    font-family: Lato,arial,sans-serif!important;
    color: #666;

    .card-close {
      text-align: left;
      margin: 10px;
    }

    .card-content {
      max-width: 384px;
      margin: 10px 5px;
      border: 1px solid #ddd;

      .card-image {
        max-width: 300px;
        margin: auto;
        padding: 28px 0;
      }
    }
    .card-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #55059c;
      font-family: arial,sans-serif!important;
    }
    .card-title-url {
        text-align: left;
    }
    .card-customize-btn {
        width: 50%;
        padding: 10px;
        color: #152733;
    }
  }

.first-card-notification {
    max-width: 375px;
    background-color: rgba(192,192,192,0.15);
    text-align: center;
    color: #000000;
    margin: auto;
}

.first-card-header {
    width: 375px;
    height: 102px;
    background-image: url("/www/img/app_header.png");
}

.first-card-body {
    margin: 10px auto;
    padding-bottom: 20px;
}

.first-card-text {
    background: #fff;
    margin-top: -10px;
    padding: 15px;
}

.divider {
    border-top: 1px solid #ddd;
}

.label-center {
    align-items: center;
    text-align: center;
}