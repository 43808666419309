.permission {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 4px 10px 5px;

    &:last-child {
        border-bottom: 1px solid #ccc;
        padding-bottom: 4px;
    }

    &__label {
        color: rgb(103, 106, 108);
        font-size: 14px;
        line-height: 16px;
        margin: 0;
        padding: 0;
        margin-right: 20px;
        white-space: nowrap;
    }

    &__switch {
        margin-left: auto;
        & > * {
            width: 100% !important;
        }

        .select {
            margin-left: auto;
            min-height: 40px;
        }
        &.multi {
            .select__value-container--is-multi {
                line-height: 0;
                padding: 0;
                .select__tooltip {
                    line-height: normal;
                }
                .select__placeholder,
                .select__multi-value {
                    line-height: 15px;
                    margin: 5px;
                }
                .select__placeholder {
                    margin: 0 5px;
                    color:#717171;
                }
            }
        }
        .select__control,
        .select,
        .select__wrapper,
        .multiselect.btn {
            width: 100% !important;
        }
        .multiselect-native-select {
            display: none;
        }
        &.multi {
            .select__wrapper {
                display: none;
            }
            .multiselect-native-select {
                display: block;
            }
        }
    }

    &__dropdown {
        display: none;
        transition: height 0.5s ease-in-out;
        overflow: hidden;
        background: #fbfbfb;
        &__wrapper {
            margin: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .flex__column--center {
                border-right: 1px solid #ccc;
                height: 100%;
                align-items: normal;
                padding: 0 10px;
                &:first-of-type {
                    padding-left: 0;
                }

                &:last-of-type {
                    border-right: 0;
                    padding-right: 0;
                }

                .toggle__wrapper {
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding: 5px 10px;
                    border-bottom: 1px solid #ccc;
                    &:first-of-type {
                        margin-top: 0;
                        border-top: 1px solid #ccc;
                    }
                    &::last-of-type {
                        border-bottom: 0;
                    }
                    h5 {
                        margin: 0;
                    }
                }
            }
        }

        &.open {
            display: block;
        }
    }
    &__column {
        display: flex;
        flex-direction: column;
    }
    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        >.editable__input--wrapper {
            flex-basis: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.editable__input:empty:not(:focus):before {
    content: attr(data-placeholder)
}
.element-edit {
    .permission {
        & > * {
            flex-basis: 75%;
        }
        &__label {
            width: 100%;
            margin-right: 20px;
            display: flex;
            flex-basis: 25%;
        }
        input {
            width: 100%;
            position: relative;
            margin-left: auto;
        }
        textarea {
            min-height: 90px;
        }
        & > input[type="text"] {
            height: 40px;
        }
        &.toggle {
            position: relative;
            display: flex;
            margin-left: 0;
            .permission__label{
                white-space: nowrap;
            }
            .toggle {
                display: flex;
                label {
                    margin-left: auto;
                }
            }
        }
        &.plainText .plainTextDiv{
            line-height: 36px;
            flex-basis: 100%;
        }
        &.link a{
            line-height: 36px;
            flex-basis: 100%;
        }
    }
}
.flex-0 {
    flex-basis: 0 !important;
}
.full_width {
    width: 100% !important;
}
