.actionablelist {
    list-style-type: none;
    width: 100%;
    position: relative;
    padding-left: 0;

    li {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;

        &:first-child {
            margin-top: 10px;
            border-top: 1px solid #ddd;
        }

        &:nth-of-type(odd) {
            background-color: #fafafa;
        }

        &.submit {
            padding: 20px;
        }

        .btn {
            margin: 0 0 0 20px;
            flex-basis: 10%;
        }

        .preElement {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-basis: 75%;

            .before {
                border-radius: 5px;
                border: 1px solid #ccc;
                background-color: #eee;
                border-right: 0;
                font-size: 14px;
                line-height: 16px;
                padding: 10px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                flex-basis: 100% !important;
            }
        }

    }
}
