.pagination {
    text-align: center;
    margin: 0;

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        margin: 0;

        li {
            background-color: transparent;
            min-width: 35px;
            position: relative;
            height: 29px;

            * {
                padding: 5px;
                font-size: 14px;
                line-height: 16px;
                height: 29px;
                margin: 0;
                width: 100%;
                display: block;
                text-align: center;
            }

            span {
                border-width: 1px;
                border-style: solid;
                border-color: $textColor;
                border-bottom-width: 2px;
                background: transparent;
                cursor: pointer;

                &.disabled {
                    cursor: default;
                }
            }

            &:first-child {
                span {
                    border-right: 0;
                }
            }

            &:last-child {
                span {
                    border-left: 0;
                }
            }

            span {
                border-bottom-width: 2px;

                &.active {
                    border-bottom-width: 2px;
                    color: #fff;
                }
            }

            .fa {
                margin: 0;
                padding: 0;
                font-size: 14px;
                line-height: 16px;
            }
            select {
                border-color: $textColor;
                margin: 0;
                cursor: pointer;
                height: 29px;
            }

            &.active {
                select {
                    border-radius: 0;
                    padding: 5px 20px 5px 15px;
                    border-bottom-width: 2px;
                }

                &:after {
                    content: "\F0D7";
                    font-family: "FontAwesome";
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
        }
    }
}
