.stream-list {
    $this: &;

    &--grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        #{$this}__item {
            border: 0;

            &--sized {
                flex: 0 1 auto;
            }

            &__wrapper {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                background: #eee;
                padding: 8px;
                position: relative;

                .tools {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 2px;
                    right: 5px;
                    font-size: 24px;
                }
            }

            &--stream {
                padding: 8px;
                margin-bottom: 20px;
            }

            &--search {
                width: 500px;
                padding: 10px;

                .stream-list__item__wrapper {
                    display: flex;
                    flex-direction: column !important;
                    border: 1px solid #ccc;
                    background: #fff;

                    span {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }

                    .close {
                        position: absolute;
                        top: 2px;
                        left: 5px;
                    }
                }
            }
        }
    }
}
