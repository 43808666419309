.w-100 {
    .react-pdf__Page__canvas{
        max-width:100%!important;
        height: unset!important;
    }
}

.mt-100px{
    margin-top: 100px;
}

.hide-col:empty {
    display: none;
}
