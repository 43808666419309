@mixin input() {
    width: 100%;
    border-radius: $borderRadius;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 10px;
}
@mixin btn() {
    border: 0;
    color: #ffffff !important;
    font-size: 16px;
    line-height: 18px;
    padding: 10px;

    button {
        background: transparent;
        border: 0;
        color: inherit;
    }

    &:hover {
        color: #ffffff;
    }

    &[disabled] {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        box-shadow: none;
        opacity: 0.65;
    }
}
