.btn {
    position: relative;
    @include btn();

    &--sm {
        font-size: 12px;
        line-height: 12px;
        padding: 5px;
    }
    width: 100%;
    @media (min-width:400px) {
        width: auto;
    }
}

.multiselect-native-select {
    .btn-group {
        .btn {
            border: 1px solid #ccc !important;
            background: #f3f3f4 !important;
            color: #676a6c !important;
            font-size: 14px;
            line-height: 16px;
            border-radius: 5px;
        }
    }
}
