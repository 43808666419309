// borrowed from the existing date picker UI:
$date-box-width: 34px;
$date-box-height:28px;
$font-color: #676a6c;
$font-color-disabled: #999999;
$selected-color: #428bca;
$calendar-main-size: 13px;
$popper-width: 266px;
$default-white: #fff;
// borrowed from react-datepicker lib
$margin: 0.4rem;




// class names should be self-explanatory. Use the inspector to find the target class
// and overwrite the style. Try to keep the order of classes in their natural hierarchy.





.react-datepicker-popper {
    margin-top: 1px;
    padding-top: 0px !important;
}
.react-datepicker {
    border: none !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10);
}
.react-datepicker-wrapper {
    width: 100% !important;
}
// To edit the date picker UI find the appropriate class in the UI
// that controls your target element and override it here.
.date-picker {
    .react-datepicker__month-container {
        font-size: $calendar-main-size;
        width:$popper-width;
    }
    .react-datepicker__navigation {
        color: $font-color;
        margin: 4px;
        width: $calendar-main-size;
    }
    .react-datepicker__navigation-icon::before {
        border-color: $font-color;
    }
    .react-datepicker__navigation--previous--disabled,
    .react-datepicker__navigation--next--disabled {
        cursor: not-allowed;
    }
    .react-datepicker__navigation--previous--disabled .react-datepicker__navigation-icon::before,
    .react-datepicker__navigation--next--disabled .react-datepicker__navigation-icon::before {
        border-color: $font-color-disabled;
    }
    .react-datepicker__header {
        text-align: center;
        background-color: $default-white;
        padding: 0;
        border-bottom: none;
    }
    .react-datepicker__current-month{
        font-size: 0px;
        padding: 6px;
    }
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll {
        color: $font-color;
        font-weight: bold;
        margin: 0 25px;
    }
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view {
        // when month/year dropdown is open keep the selected month/year
        // visible
        visibility: visible !important;
    }
    .react-datepicker__day-names{
        font-weight: bold;
    }
    .react-datepicker__day-name,
    .react-datepicker__day
    {
        color: $font-color;
        width: $date-box-width;
        line-height: $date-box-height;
    }

    .react-datepicker__day-names
    {
        color: $font-color;
    }
    .react-datepicker__day--selected{
        color: $default-white;
        background-color: $selected-color;
        border-radius: 0;
    }
    .react-datepicker__day--today {
        font-weight: normal;
        padding-left: 7px;
    }

    .react-datepicker__day--selected.react-datepicker__day--today {
        padding-bottom: 1px;
    }
    // add the blue triangle to the bottom right corner of today
    .react-datepicker__day--today::after{
        content: "";
        border-left: 7px solid transparent;
        border-bottom: 7px solid $selected-color;
        border-top-color: rgba(0, 0, 0, 0.2);
    }
    .react-datepicker__day--disabled {
        color: $font-color-disabled;
        cursor: not-allowed;
    }
    .react-datepicker-time__header {
        font-size: $calendar-main-size;
        padding-top: 8px;
        padding-bottom: 8px;
        color: $font-color;
    }

    .react-datepicker__time-list-item {
        font-size: $calendar-main-size;
        color: $font-color;
    }
}
