.page.settings {
    .permissions {
        .permission {
            .permission__label {
                width: 200px;
                display: flex;
                align-items: center;
            }
        }
    }
}
