.ibox.number {
    height: 186.5px;
}

.number-chart {
    .ibox-title {
        padding-bottom: 20px;
        overflow: hidden;
        position: relative;

        h5 {
            margin-bottom: 0;
            float: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        .ibox-tools {
            .filters {
                margin-left: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .filter a span {
                    text-decoration: underline;
                }
            }
        }
    }

    .ibox-content {
        padding: 0 20px;
        transition: height ease-in-out 200ms;
        height: 100px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        & > div {
            width: 100%;
        }

        .overall .reportCard__summary{
            height: 115px;
        }

        .reportCard__summary {
            height: 85px;
            .reportCard__summary__section {
                height: 100%;
                flex: 1;
                h3 {
                    margin-top: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    .value {
                        font-size: 30px;
                        line-height: 30px;
                        padding-bottom: 10px;
                    }
                    sup {
                        font-size: 50%;
                        top: -0.75em;
                    }
                    small {
                        line-height: 18px;
                        font-size: 18px;
                        padding: 0 10px;
                        margin: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                    }
                }
            }
        }
        .content-footer {
            width: 100%;
            height: 0;
            position: relative;

            span {
                position: absolute;
                top: 0;
                width: 100%;
                display: inline-block;
                text-align: right;
                font-size: 11px;
                color: rgb(103, 106, 108);
            }
        }
    }

    .ibox-footer {
        border: 0;
        padding-top: 0;
        text-align: center;
    }

    .filterIcon {
        position: absolute;
        bottom: -10px;
        width: 30px;
        height: 30px;
        color: #bababa;
        font-size: 30px;
        right: -30px;
        transition: right ease-in-out 200ms;
    }
    .row.dropdown {
        display: flex;
        justify-content: center;
        background: #eee;
        width: 100%;
        position: relative;
        transition: all ease-in-out 200ms;
        height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;

        &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            box-shadow: inset 0px 7px 9px -7px rgba(0, 0, 0, 0.4), inset 0 -7px 9px -7px;
            z-index: 999;
            pointer-events: none;
            transition: height ease-in-out 200ms;
        }

        .content {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin-bottom: 10px;
            transition: margin ease-in-out 200ms;
        }
    }
    &.open {
        .row.dropdown {
            height: 100px;
            margin: 0;
            margin-bottom: 20px;
            overflow: visible;

            &:before {
                height: 100px;
            }
        }

        .filterIcon {
            right: 60px;
        }

        .content {
            padding: 10px;
        }

        .ibox-content {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    &.animating {
        .row.dropdown {
            overflow: hidden;
        }
    }
}
