/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "variables";
@import "mixins";

// INSPINIA Theme Elements
@import "base";
@import "typography";
@import "navigation";
@import "top_navigation";
@import "buttons";
@import "badges_labels";
@import "elements";
@import "sidebar";
@import "pages";
@import "chat";
@import "metismenu";
@import "spinners";

// Landing page styles
@import "landing";

// RTL Support
@import "rtl";

// Media query style
@import "media";


// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}
