.notification_bar {
    padding: 10px;
    background-color: #cb8a3a;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}
