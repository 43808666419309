@font-face {
    font-family: 'icomoon';
    src: url("../../../../www/dist/fonts/fontIcons/icomoon.eot?8bu050");
    src: url("../../../../www/dist/fonts/fontIcons/icomoon.eot?8bu050#iefix") format('embedded-opentype'), url("../../../../www/dist/fonts/fontIcons/icomoon.ttf?8bu050") format('truetype'), url("../../../../www/dist/fonts/fontIcons/icomoon.woff?8bu050") format('woff'), url("../../../../www/dist/fonts/fontIcons/icomoon.svg?8bu050#icomoon") format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="iconGV6-"],
[class*=" iconGV6-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconGV6-schedule:before {
    content: "\e905";
}

.iconGV6-scheduled:before {
    content: "\e906";
}

.iconGV6-Notifications:before {
  content: "\e91a";
}

.ion {
    font-size: 14px;
    display: inline-block;
    font-family: "Ionicons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ion-android-contacts:before {
    content: "\f2d9";
}

.ion-speakerphone:before {
    content: "\f2b2";
}

.ion-android-home:before {
    content: "\f38f";
}

.ion-briefcase:before {
    content: "\f26c";
}

.ion-android-list:before {
    content: "\f391";
}

.ion-document-text:before {
    content: "\f12e";
}

.nav-hr{
    width: 80%;
    border-top: 1px solid #a7b1c242;
}

