@mixin colorSet($color, $cls, $mod, $colorMod: "") {
    #{$cls}--color#{$mod} {
        color: $color unquote($colorMod);
    }

    #{$cls}--bg#{$mod} {
        background-color: $color unquote($colorMod);
    }

    #{$cls}--border#{$mod} {
        border-color: $color unquote($colorMod);
    }

    #{$cls}--stroke#{$mod} {
        stroke: $color unquote($colorMod);
    }
}

$brandColor: $brandPrimary;

.brandPrimary a {
    color: $brandColor;

    &:hover {
        color: darken($brandColor, 10%);
    }
}

.brandPrimaryText {
    color: $brandColor !important;
}

.brandPrimaryBorder {
    background-color: white !important;
    border-color: $brandColor !important;
    border: solid !important;
}

a.brandPrimary--bg, button.brandPrimary--bg {
    background-color: $brandColor !important;

    &:hover {
        background-color: darken($brandColor, 10%) !important;
    }

    &:focus {
        text-decoration: underline !important;
    }
}

button.brandPrimary--color {
    background: none;
    border: none;
}

@include colorSet($brandColor, ".brandPrimary", "");
@include colorSet($brandColor, ".brandPrimary", "__important", !important);
@include colorSet($brandColor, ".brandPrimary", ".active");
@include colorSet(darken($brandColor, 10%), "button.brandPrimary", ":hover");
@include colorSet(darken($brandColor, 10%), "a.brandPrimary", ":hover");
@include colorSet(darken($brandColor, 10%), ".brandPrimary__dark", "");
@include colorSet(lighten($brandColor, 45%), ".brandPrimary__light", "");
@include colorSet(lighten($brandColor, 45%), ".brandPrimary__light__important", "", !important);

// .brandPrimary a {
//     color: magenta;
// }

// .brandPrimary a:hover {
//     color: #cc00cc;
// }

// a.brandPrimary--bg {
//     background-color: magenta !important;
// }

// a.brandPrimary--bg:hover {
//     background-color: #cc00cc !important;
// }

// .brandPrimary--color {
//     color: magenta;
// }

// .brandPrimary--color:hover {
//     color: #cc00cc;
// }

// .brandPrimary--bg {
//     background-color: magenta;
// }

// .brandPrimary--border {
//     border-color: magenta;
// }

// .brandPrimary--color.active {
//     color: magenta;
// }

// .brandPrimary--color.active:hover {
//     color: #cc00cc;
// }

// .brandPrimary--bg.active {
//     background-color: magenta;
// }

// .brandPrimary--border.active {
//     border-color: magenta;
// }

// button.brandPrimary--color:hover {
//     color: #cc00cc;
// }

// button.brandPrimary--color:hover:hover {
//     color: #990099;
// }

// button.brandPrimary--bg:hover {
//     background-color: #cc00cc;
// }

// button.brandPrimary--border:hover {
//     border-color: #cc00cc;
// }

// a.brandPrimary--color:hover {
//     color: #cc00cc;
// }

// a.brandPrimary--color:hover:hover {
//     color: #990099;
// }

// a.brandPrimary--bg:hover {
//     background-color: #cc00cc;
// }

// a.brandPrimary--border:hover {
//     border-color: #cc00cc;
// }

// .brandPrimary__dark--color {
//     color: #cc00cc;
// }

// .brandPrimary__dark--color:hover {
//     color: #990099;
// }

// .brandPrimary__dark--bg {
//     background-color: #cc00cc;
// }

// .brandPrimary__dark--border {
//     border-color: #cc00cc;
// }

// .brandPrimary__light--color {
//     color: #ffe6ff;
// }

// .brandPrimary__light--color:hover {
//     color: #ffb3ff;
// }

// .brandPrimary__light--bg {
//     background-color: #ffe6ff;
// }

// .brandPrimary__light--border {
//     border-color: #ffe6ff;
// }

// .brandPrimary__light__important--color {
//     color: #ffe6ff !important;
// }

// .brandPrimary__light__important--color:hover {
//     color: #ffb3ff !important;
// }

// .brandPrimary__light__important--bg {
//     background-color: #ffe6ff !important;
// }

// .brandPrimary__light__important--border {
//     border-color: #ffe6ff !important;
// }

.customBrandInput {
    display: block;
    border: none;
    padding-left: 0;
    padding-right: 0;
    &:last-child {
        border:none;
    }
    span {
        font-weight: bold;
        margin-bottom: 0.5em;
    }
}