.list-of-tags .select__multi-value {
    background-color: rgba(21, 39, 138, 0.2);
    border: 1px solid #152733;
    color: #152733;
}

.list-of-tags .select__multi-value__remove:hover, .select--multi .select__multi-value__remove:focus {
    background-color: #1527336e;
    color: #152733;
}
.list-of-tags .select__multi-value.select-mention {
    background-color: rgba(241, 88, 42, 0.2);
    border: 1px solid #f1592a;
    color: #f1592a;
}
.list-of-tags .select__multi-value.select-mention .select__multi-value__remove:hover, .select--multi .select__multi-value.select-mention .select__multi-value__remove:focus {
    background-color: #f1592a6e;
    color: #f1592a;
}
.Hashtags-field input, .Mentions-field input{
    padding-left: 25px;
    width: 200px!important;
}
.Hashtags-field:after, .Mentions-field:after{
    width: 25px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'FontAwesome';
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 7px;
}
.Hashtags-field:after {
    content: '\F292';
}
.Mentions-field:after {
    content: '\F1FA';
}
.term-field input{
    width: 200px!important;
}
.list-of-tags-positive .select__multi-value__remove {
    padding: 0px 13px 0px 8px;
    width: 0;
    font-size: 0px;
}
.list-of-tags-positive .select__multi-value__remove::before{
    color: #152733;
    font-size: 11.7px;
    content: '+';
    padding-top: 2px;
    padding-bottom: 3px;
    padding-right: 2px;
}
.list-of-tags-positive.select__multi-value {
    background-color: white;
}
.list-of-tags-positive .select__multi-value-label {
    background-color: white;
}
.list-of-tags-positive.select-mention-positive .select__multi-value__remove::before{
    color: #f1592a;
}
.list-of-tags-positive.select-mention-positive.select__multi-value {
    border: 1px solid #f1592a;
    color: #f1592a;
}
.list-of-tags-positive.select-mention-positive .select__multi-value__remove:hover, .select--multi .list-of-tags-positive.select-mention-positive .select__multi-value__remove:focus {
    background-color: #ff9c0033;
    color: #8a5f30;
}
.select--multi.is-disabled .select__multi-value {
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
    color: #333;
}