.checkbox-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    &__title {
        font-size: 13px;
        font-weight: bold;
    }
    &__row {
        display: flex;
    }
    &__option {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 10px 5px;
    }
    &__label {
        color: #676a6c;
        line-height: 16px;
        margin: 0;
        padding: 0;
        margin-right: 5px;
        white-space: nowrap;
        & .disabled {
            color: #ccc;
        }
    }
}