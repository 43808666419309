@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import "react-datetime";
@import "bootstrap-multiselect";
@import "variables";
@import "responsive-tables";
@import "mixins";
@import "login";
@import "old/style";
@import "buttons";
@import "forms";
@import "page";
@import "spinner";
@import "icons";
@import "typography";
@import "spacing";
@import "article-card";
@import "stream-list";
@import "autosuggestdomain";
@import "report-card";
@import "modals";
@import "alerts";
@import "pagination";
@import "actionablelist";
@import "listOfTags";
@import "permissions";
@import "toggle";
@import "notification-bar";
@import "flex";
@import "swagger";
@import "tables";
@import "brand";
@import "calendar";
@import "ibox";
@import "number-chart";
@import "percent-circle";
@import "element-list-box";
@import "pages";
@import "event-calendar";
@import "date-picker";
@import "checkbox-section";

.row__controls {
    vertical-align: middle !important;
    border-left: $border;

    .controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a.btn {
            width: 100%;
        }
    }
}

.panel_wrap {
    white-space: normal;
    width: 100%;

    @media (max-width: 768px) {
        > .col-lg-6,
        > .col-lg-12 {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.multiselect-native-select .btn-group .btn {
    background: #fff !important;
}

.table-responsive {
    overflow-x: unset;
    word-break: break-all;
    white-space: normal;
    overflow-y: auto;
}

@media screen and (max-width: 767px) {
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > td,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > thead > tr > th {
        white-space: normal;
        word-break: break-all;
    }
}

@media (min-width: 768px) {
    .navbar-right {
        margin-right: 0px;
    }
}

.app {
    display: inline-block;
    min-width: 100%;
}

.maintenance {
    .permission__switch .select__control,
    .permission__switch .select {
        width: 100% !important;
    }
}

.skip-to-content-button {
    transform: translateY(-410%);
}

.skip-to-content-button:focus {
    margin-top: 5px;
    transition: 0.2s;
    transform: translateY(0%);
}



