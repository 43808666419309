.page {
    &-heading {
        display: flex;
        align-items: center;
        flex-direction: column;

        h1 {
            margin: 0;
        }

        .col-sm-6 {
            display: flex;
            flex-direction: row;
            align-items: center;

            .btn {
                margin-left: 10px;

                &:first-child {
                    margin-left: auto;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            .btn {
                margin-top: 20px;
            }
        }

        @media (min-width: 1024px) {
            flex-direction: row;
            .buttons {
                margin-left: auto;
                .btn {
                    margin-top: 0;
                }
            }
        }
    }
}

.table {
    &__spacing {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    td[colspan] {
        text-align: center;

        .btn {
            margin: 0 auto;
            width: 25%;
        }
    }

    &-responsive {
        display: block;
        width: 100%;
    }

    &__description {
        display: block;
        width: 100%;
        text-align: right;
        margin-right: 5px;
    }

    thead {
        tr {
            th {
                white-space: nowrap;
            }
        }
    }
}

.load-more {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .btn {
        width: 25%;
    }
}

#addarticle {
    width: 75px;
    height: 75px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.75);

    .fa {
        font-size: 35.5px;
    }
}

.ReactPDF__Document {
    .ReactPDF__Page {
        width: 100% !important;

        .ReactPDF__Page__canvas {
            width: 100% !important;
        }
    }
}

.disabled {
    opacity: 0.3;
}

div[data-id="tooltip"] {
    z-index: 9999;
}

.footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.roles {
    .filter__search {
        width: 100% !important;
    }

    .table__filters {
        width: 100%;
        display: block;
    }
    .role__selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            cursor: pointer;
        }
    }
}

/*  Stream Subscription */
@media (max-width: 1199px) {
    .noBorder {
        border: none !important;
    }
    .rwd-break {
        display: none;
    }
}
@media (min-width: 1199px) {
    .mt-70 {
        margin-top: 70px;
    }
}

.page {
    position: fixed;
    height: calc(100%);
    width: calc(100% - 220px);
}
.mini-navbar {
    .page {
        width: 100vw;
    }
}
